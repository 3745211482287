import React from "react";
import { Link, useLocation } from 'react-router-dom';
import {
    WalletConnect,
    WaveMe,
    StakeNFT,
    NFTGame,
    Lobby,
} from "../../components/Gadget";
import { useAccount } from "wagmi";
import { Flex } from "crox-new-uikit";
import useMediaQuery from "use-mediaquery";
import "../../assets/style/scss/style-common.scss";
import "../../assets/style/scss/style-basic.scss";
import "./home.css";

function Home(props) {
    const { collapse } = props;
    const { address } = useAccount();
    const { search } = useLocation();
    const isMobile = useMediaQuery("(max-width: 600px)");
    return (
        <Flex
            className={
                !collapse
                    ? "container mobile-home"
                    : "container mobile-home wider-width"
            }
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={50}
            mb={80}
        >
            {!address && (
                <Flex
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                >
                    <WalletConnect />
                </Flex>
            )}
            <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection={isMobile ? "column" : "row"}
            >
                <Flex m={10} />
                <Lobby to={search} />
                <Flex m={10} />
                <NFTGame to={search} />

                {/*<Flex m={10} />
                <StakeNFT />*/}
            </Flex>
        </Flex>
    );
}

export default Home;
