import React, { useEffect, useState } from "react";
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarContent,
    SidebarFooter,
    SubMenu,
} from "react-pro-sidebar";
import { useWeb3React } from "@web3-react/core";
import { Flex, Text } from "crox-new-uikit";
import { Icon } from "@iconify/react";
import ReactModal from "react-modal";
import { injected } from "../connector";
import ReactiveButton from "reactive-button";
import useMediaQuery from "use-mediaquery";
import "../../assets/style/scss/style-common.scss";
import "../../assets/style/scss/style-basic.scss";
import "./connectbutton.css";

import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsAvatar,
    useEnsName,
} from "wagmi";

let isConfirm = false;

function ConnecButton(props) {
    const { handleChild, setToggle } = props;

    const { address, connector, isConnected } = useAccount();
    const { data: ensAvatar } = useEnsAvatar({ addressOrName: address });
    const { data: ensName } = useEnsName({ address });
    const { connect, connectors, error, isLoading, pendingConnector } =
        useConnect();
    const { disconnect } = useDisconnect();

    const [isNetworkSelectModalOpen, setIsNetworkSelectModalOpen] =
        useState(false);

    const handleLogin = () => {
        isConfirm = true;
        localStorage.setItem("accountStatus", "1");
        return connect({ connector });
    };

    const handleLogout = () => {
        isConfirm = false;
        localStorage.removeItem("accountStatus");
        disconnect();
    };

    function copyToClipBoard() {
        var x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function () {
            x.className = x.className.replace("show", "");
        }, 3000);
    }

    function closeModal() {
        setIsNetworkSelectModalOpen(false);
    }

    const customStyles = {
        content: {
            top: "65px",
            left: "auto",
            right: "10px",
            bottom: "auto",
            backgroundColor: "transparent",
            border: "none",
            overflow: "hidden",
        },
    };

    ReactModal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0)";

    const isMobile = useMediaQuery("(max-width: 600px)");

    return (
        <>
            <Flex justifyContent={isMobile ? "space-between" : "flex-end"}>
                {isMobile && (
                    <Flex m="20px 10px">
                        <ReactiveButton
                            idleText={
                                <Icon
                                    icon="line-md:menu-unfold-left"
                                    width="24"
                                    height="24"
                                />
                            }
                            onClick={() => setToggle(true)}
                        />
                    </Flex>
                )}
                {address && (
                    <Flex m={isMobile ? "20px 10px" : "20px"}>
                        <ReactiveButton
                            idleText={
                                <Flex alignItems="center">
                                    <lottie-player
                                        autoplay
                                        loop
                                        mode="normal"
                                        src="https://assets7.lottiefiles.com/packages/lf20_yZpLO2.json"
                                        style={{
                                            width: "60px",
                                            margin: "-20px -10px -10px",
                                        }}
                                    />
                                    <Text ml="5px" mr="10px" bold>
                                        {address.slice(0, 4)}...
                                        {address.slice(-4)}
                                    </Text>
                                    <Icon
                                        icon="ant-design:caret-down-filled"
                                        color="#fff"
                                        width="15"
                                        height="15"
                                    />
                                </Flex>
                            }
                            size="large"
                            onClick={() => setIsNetworkSelectModalOpen(true)}
                        />
                        <ReactModal
                            isOpen={isNetworkSelectModalOpen}
                            onRequestClose={() => closeModal()}
                            style={customStyles}
                        >
                            <Flex
                                flexDirection="column"
                                className="accountModal"
                            >
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Text bold>Account</Text>
                                    <ReactiveButton
                                        idleText={
                                            <Flex alignItems="center">
                                                <Icon
                                                    icon="clarity:logout-line"
                                                    color="#D9511C"
                                                    width="15"
                                                    height="15"
                                                />
                                                <Text fontSize="12px" ml="3px">
                                                    Logout
                                                </Text>
                                            </Flex>
                                        }
                                        size="small"
                                        onClick={handleLogout}
                                        outline
                                        rounded
                                    />
                                </Flex>
                                <Flex alignItems="center" mt="20px">
                                    <Icon
                                        icon="et:wallet"
                                        color="#D9511C"
                                        width="30"
                                        height="30"
                                    />
                                    <Text m="0 10px" bold>
                                        {address.slice(0, 7)}...
                                        {address.slice(-7)}
                                    </Text>
                                    <div
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                address
                                            );
                                            copyToClipBoard();
                                        }}
                                        className="cursor-pointer"
                                    >
                                        <Icon
                                            icon="fluent:copy-24-filled"
                                            color="rgba(255,255,255,0.3)"
                                            width="30"
                                            height="30"
                                        />
                                    </div>
                                </Flex>
                            </Flex>
                        </ReactModal>
                        <div id="snackbar"><Text fontSize="24px" ml="5px" mr="1px" color="cyan" >Copied</Text></div>
                        
                    </Flex>
                )}
            </Flex>
        </>
    );
}

// const Button = styled.button`
//     border-radius: 8px;
//     padding: 10px 18px;
//     font-weight: 600;
//     box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
//     background-color: ${({ primary }) => (primary ? "#d9511c" : "#171615")};
//     color: ${({ primary }) => (primary ? "#f1ede9" : "#d9511c")};
//     border: ${({ secondary }) => secondary && "1px solid #d9511c"};
//     height: auto;
// `;

export default ConnecButton;
