import * as React from "react";
import styled from "styled-components";

function Landing() {
    const partners = [
        { logo: "images/logoimg.png", name: "Logoimg" },
        { logo: "images/logoimg.png", name: "Logoimg" },
        { logo: "images/logoimg.png", name: "Logoimg" },
        { logo: "images/logoimg.png", name: "Logoimg" },
    ];

    const stats = [
        {
            value: "10M+",
            label: "Total Value Locked (TVL)",
            description:
                "Total PLS tokens invested by users, indicating the platform's scale and trust.",
        },
        {
            value: "18%",
            label: "Annual Percentage Rate",
            description:
                "Average yearly return on staked PLS, highlighting potential earnings.",
        },
        {
            value: "5K+",
            label: "Active Players",
            description:
                "Users actively participating, showing community engagement.",
        },
        {
            value: "$2M+",
            label: "Dividends Paid",
            description:
                "Total rewards distributed, reflecting the platform's profitability.",
        },
    ];

    const ecosystemApps = [
        {
            logo: "images/ecosystem_logo.png",
            name: "MateMask",
            description: "A crypto wallet and gateway to blockchain apps.",
        },
        {
            logo: "images/ecosystem_logo.png",
            name: "Curve",
            description: "Use (w)stETH or stMATIC in Curve liquidity pools.",
        },
        {
            logo: "images/ecosystem_logo.png",
            name: "AAVE",
            description: "Borrow tokens against (w)stETH and stMATIC.",
        },
        {
            logo: "images/ecosystem_logo.png",
            name: "1inch",
            description:
                "Exchange and provide liquidity with (w)stETH and stMATIC.",
        },
        {
            logo: "images/ecosystem_logo.png",
            name: "Ledger crypto wallet",
            description: "Stake directly from Ledger crypto wallet.",
        },
        {
            logo: "images/ecosystem_logo.png",
            name: "Balancer",
            description: "Borrow tokens against (w)stETH and stMATIC.",
        },
        {
            logo: "images/ecosystem_logo.png",
            name: "UniSwap",
            description:
                "Create a new liquidity pool, provide liquidity, and swap tokens.",
        },
        {
            logo: "images/ecosystem_logo.png",
            name: "MakerDAO",
            description:
                "Use wstETH for borrowing or multiplying while getting staking rewards.",
        },
    ];

    return (
        <Wrapper>
            {/* <Header>
                <Logo
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f159d4f0417a53d44539e7212fcf1064bf24021f38ad20af7603fcb2ef13c19b?apiKey=c2eace46523148b195c70f9101a6de88&"
                    alt="Logo"
                />
                <Nav>
                    <ul>
                        <li>
                            <a href="#home">Home</a>
                        </li>
                        <li>
                            <a href="#ecosystem">Ecosystem</a>
                        </li>
                        <li>
                            <a href="#auction">Auction</a>
                        </li>
                        <li>
                            <a href="#pulse-miner">Pulse Miner</a>
                        </li>
                    </ul>
                    <Button primary>Login</Button>
                </Nav>
            </Header> */}
            <Main>
                <HeroSection className="pgsection">
                    <HeroImage src="/images/herobg.svg" alt="Hero" />
                    <HeroContent>
                        <HeroTagContainer>
                            <HeroTag>DeFi</HeroTag>
                            <HeroTag>Tru 2x Games</HeroTag>
                        </HeroTagContainer>
                        <HeroTitle>Double Your Crypto with Tru 2X</HeroTitle>
                        <HeroDescription>
                            A DeFi service tool to double your crypto or funds
                            by sharing real DeFi service fee revenues. No token
                            purchase required in all Tru 2x games.
                        </HeroDescription>
                        <HeroButtons>
                            <Button secondary>
                                <HeroIcon
                                    src="/images/watchimg.svg"
                                    alt="Video"
                                />
                                Video
                            </Button>
                            <Button primary>Double your Funds</Button>
                        </HeroButtons>
                    </HeroContent>
                </HeroSection>
                <Section id="discover" className="pgsection">
                    <SectionTitle>Discover</SectionTitle>
                    <SectionSubtitle>Hows Tru 2X Games work</SectionSubtitle>
                    <SectionDescription>
                        Tru 2X Games are designed to maximize returns for
                        participants through a gamified DeFi experience.
                    </SectionDescription>
                    <Cards>
                        <Card>
                            <CardImage
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c6e74c66e6d3ee3d2bc396b5d70582f1ef123f9b188a0ab775087a5a48850e7f?apiKey=c2eace46523148b195c70f9101a6de88&"
                                alt="Deposit PLS"
                            />
                            <CardTitle>Deposit PLS</CardTitle>
                            <CardDescription>
                                Securely add your PLS to start the process.
                            </CardDescription>
                        </Card>
                        <Card>
                            <CardImage
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/62381b76cf3aa2e60c650fbcd5a693fb896888fd9c557865520a491817e52fbc?apiKey=c2eace46523148b195c70f9101a6de88&"
                                alt="Create PLS Miner"
                            />
                            <CardTitle>Create PLS Miner</CardTitle>
                            <CardDescription>
                                Set up your PLS Miner to begin earning.
                            </CardDescription>
                        </Card>
                        <Card>
                            <CardImage
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/b4d6e5f3055d6ce6bd88aa66607dca00694174f317cdd0bb6525d168f696a695?apiKey=c2eace46523148b195c70f9101a6de88&"
                                alt="Receive up to 2X ROI"
                            />
                            <CardTitle>Receive up to 2X ROI</CardTitle>
                            <CardDescription>
                                Earn up to 2X return on your PLS investment.
                            </CardDescription>
                        </Card>
                    </Cards>
                </Section>
                <PartnersSection className="pgsection">
                    <PartnersTitle>Our Partners</PartnersTitle>
                    <PartnersLogos>
                        {partners.map((partner, index) => (
                            <Partner key={index}>
                                <PartnerLogoWrapper>
                                    <PartnerLogo
                                        src={partner.logo}
                                        alt={partner.name}
                                    />
                                </PartnerLogoWrapper>
                                <PartnerName>{partner.name}</PartnerName>
                            </Partner>
                        ))}
                    </PartnersLogos>
                </PartnersSection>
                <StatsSection className="pgsection">
                    <StatsBackground src="images/bg2sec.svg" alt="Background" />
                    <StatsContainer>
                        {stats.map((stat, index) => (
                            <Stat key={index}>
                                <StatValue>{stat.value}</StatValue>
                                <StatLabel>{stat.label}</StatLabel>
                                <StatDescription>
                                    {stat.description}
                                </StatDescription>
                            </Stat>
                        ))}
                    </StatsContainer>
                </StatsSection>
                <EcosystemSection className="pgsection">
                    <EcosystemTitle>Lido ecosystem</EcosystemTitle>
                    <EcosystemDescription>
                        Explore apps and services within Lido ecosystem to get
                        more benefits with your staked tokens.
                    </EcosystemDescription>
                    <EcosystemApps>
                        {ecosystemApps.map((app, index) => (
                            <EcosystemApp key={index}>
                                <AppCard>
                                    <AppLogo src={app.logo} alt={app.name} />
                                    <AppDetails>
                                        <AppName>{app.name}</AppName>
                                        <AppDescription>
                                            {app.description}
                                        </AppDescription>
                                    </AppDetails>
                                </AppCard>
                            </EcosystemApp>
                        ))}
                    </EcosystemApps>
                </EcosystemSection>
                <CallToActionSection className="pgsection">
                    {/* <CallToActionBackground /> */}
                    <CallToActionBackground
                        src="/images/joinbg.png"
                        alt="Join"
                    />
                    <CallToActionContainer>
                        <CallToActionTitle>
                            Join 2K+ users automating DeFi with TETRA
                        </CallToActionTitle>
                        <CallToActionFeatures>
                            <Feature>
                                <FeatureIcon
                                    src="/images/checkicon.svg"
                                    alt="Feature"
                                />
                                Easy-to-use toolbox
                            </Feature>
                            <Feature>
                                <FeatureIcon
                                    src="/images/checkicon.svg"
                                    alt="Feature"
                                />
                                Streamline your blockchain tasks
                            </Feature>
                            <Feature>
                                <FeatureIcon
                                    src="/images/checkicon.svg"
                                    alt="Feature"
                                />
                                Full control of keys and coins
                            </Feature>
                            <Feature>
                                <FeatureIcon
                                    src="/images/checkicon.svg"
                                    alt="Feature"
                                />
                                Save time and effort
                            </Feature>
                            <Feature>
                                <FeatureIcon
                                    src="/images/checkicon.svg"
                                    alt="Feature"
                                />
                                Enhance your crypto experience
                            </Feature>
                        </CallToActionFeatures>
                        <CallToActionButtons>
                            <Button secondary>Learn more</Button>
                            <Button primary>Get Started</Button>
                        </CallToActionButtons>
                    </CallToActionContainer>
                </CallToActionSection>
            </Main>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-color: var(--Surface, #171615);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Inter, sans-serif;
    .pgsection {
        box-sizing: border-box;
        padding-right: 160px;
        padding-left: 160px;
        @media screen and (max-width: 1250px) {
            padding-right: 80px !important;
            padding-left: 80px !important;
        }
        @media screen and (max-width: 991px) {
            padding-right: 60px !important;
            padding-left: 60px !important;
        }
        @media screen and (max-width: 600px) {
            padding-right: 40px !important;
            padding-left: 40px !important;
        }
    }
`;

const Header = styled.header`
    display: flex;
    width: 100%;
    max-width: 1216px;
    justify-content: space-between;
    padding: 26px;
    @media (max-width: 991px) {
        flex-wrap: wrap;
    }
`;

const Logo = styled.img`
    width: 84px;
    aspect-ratio: 1.49;
    object-fit: cover;
`;

const Nav = styled.nav`
    display: flex;
    gap: 20px;
    ul {
        display: flex;
        gap: 20px;
        list-style: none;
    }
    li {
        font-weight: 500;
    }
    a {
        color: #fff;
        text-decoration: none;
    }
    @media (max-width: 991px) {
        flex-wrap: wrap;
    }
`;

const Button = styled.button`
    border-radius: 8px;
    padding: 10px 18px;
    font-weight: 600;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background-color: ${({ primary }) => (primary ? "#d9511c" : "#171615")};
    color: ${({ primary }) => (primary ? "#f1ede9" : "#d9511c")};
    border: ${({ secondary }) => secondary && "1px solid #d9511c"};
`;

const Main = styled.main`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const HeroSection = styled.section`
    position: relative;
    display: flex;
    min-height: 70vh;
    align-items: center;
    justify-content: center;
    padding: 80px 60px;
    width: 100%;

    @media (max-width: 991px) {
        padding: 0 20px;
    }
`;

const HeroImage = styled.img`
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const HeroContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 768px;
`;

const HeroTagContainer = styled.div`
    display: flex;
    gap: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 143%;
`;

const HeroTag = styled.span`
    border-radius: 16px;
    padding: 2px 10px;
    background-color: rgba(180, 88, 59, 0.2);
    color: #ff6e36;
`;

const HeroTitle = styled.h1`
    color: #f1ede9;
    font-size: 60px;
    line-height: 120%;
    letter-spacing: -1.2px;
    margin-top: 16px;
    @media (max-width: 991px) {
        font-size: 40px;
    }
`;

const HeroDescription = styled.p`
    color: rgba(199, 185, 178, 0.8);
    font-size: 20px;
    line-height: 30px;
    margin-top: 24px;
`;

const HeroButtons = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 48px;
    font-size: 18px;
    color: #f1ede9;
    line-height: 156%;
    ${Button} {
        display: flex;
        align-items: center;
    }
    @media (max-width: 991px) {
        margin-top: 40px;
    }
`;

const HeroIcon = styled.img`
    width: 24px;
    margin-right: 8px;
`;

const Section = styled.section`
    width: 100%;
    max-width: 1216px;
    padding: 0 20px;
    @media (max-width: 991px) {
        margin-top: 40px;
    }
`;

const SectionTitle = styled.h2`
    color: #ff6e36;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-top: 99px;
    @media (max-width: 991px) {
        margin-top: 40px;
    }
`;

const SectionSubtitle = styled.h3`
    color: #f1ede9;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.72px;
    margin-top: 12px;
`;

const SectionDescription = styled.p`
    color: #c2b7b1;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    width: 768px;
    margin-top: 12px;
    @media (max-width: 991px) {
        width: 100%;
    }
`;

const Cards = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 72px;
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 33%;
    @media (max-width: 991px) {
        width: 100%;
        margin-top: 32px;
    }
`;

const CardImage = styled.img`
    width: 52px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
    background-color: rgba(175, 71, 38, 0.2);
    align-self: center;
    height: 52px;
`;

const CardTitle = styled.h4`
    color: #f1ede9;
    margin-top: 18px;
    font-size: 20px;
    font-weight: 500;
`;

const CardDescription = styled.p`
    color: rgba(199, 185, 178, 0.8);
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
`;

const PartnersSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 80px 32px;
    background: linear-gradient(
        180deg,
        rgba(65, 59, 55, 0.08) 0%,
        rgba(65, 59, 55, 0.2) 100%
    );
    margin-top: 99px;
    @media (max-width: 991px) {
        padding: 0 20px;
        margin-top: 40px;
    }
`;

const PartnersTitle = styled.h4`
    color: #ffd2c0;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-top: 19px;
`;

const PartnersLogos = styled.div`
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    width: 100%;
    margin-top: 32px;
    @media (max-width: 991px) {
        flex-wrap: wrap;
    }
`;

const Partner = styled.div`
    display: flex;
    // flex-direction: column;
    align-items: center;
    gap: 14px;
`;

const PartnerLogoWrapper = styled.div`
    border-radius: 50%;
    // background-color: #ff6e36;
    display: flex;
    align-items: center;
    width: 44px;
    height: 44px;
`;

const PartnerLogo = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const PartnerName = styled.div`
    color: #ff6e36;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
`;

const StatsSection = styled.section`
    position: relative;
    display: flex;
    min-height: 699px;
    align-items: center;
    padding: 80px;
    width: 100%;
    // margin-top: 99px;
    @media (max-width: 991px) {
        padding: 0 20px;
        margin-top: 40px;
    }
`;

const StatsBackground = styled.img`
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const StatsContainer = styled.div`
    position: relative;
    display: flex;
    flex-flow: wrap;
    max-width: 768px;
    width: 100%;
    @media (max-width: 991px) {
        margin-top: 40px;
    }
`;

const Stat = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 40px;
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
    @media (max-width: 600px) {
        width: 100%;
    }
`;

const StatValue = styled.div`
    color: #f1ede9;
    letter-spacing: -1.08px;
    font-size: 54px;
    font-weight: 600;
    @media (max-width: 991px) {
        font-size: 40px;
    }
`;

const StatLabel = styled.div`
    color: #ff6e36;
    margin-top: 12px;
    font-size: 18px;
    font-weight: 500;
    line-height: 156%;
`;

const StatDescription = styled.div`
    color: #c2b7b1;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
`;

const EcosystemSection = styled.section`
    text-align: center;
    margin-top: 99px;
    padding: 0 20px;
    padding-bottom: 100px;
    @media (max-width: 991px) {
        margin-top: 40px;
    }
`;

const EcosystemTitle = styled.h2`
    color: #f1ede9;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.72px;
`;

const EcosystemDescription = styled.p`
    color: #c2b7b1;
    font-size: 20px;
    font-weight: 400;
    width: 768px;
    margin: 12px auto 0;
    @media (max-width: 991px) {
        width: 100%;
    }
`;

const EcosystemApps = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 64px;
    justify-content: flex-start;

    @media (max-width: 991px) {
        gap: 0;
    }
`;

const EcosystemApp = styled.div`
    flex: 1 0 30%; /* three apps per row on large screens */
    max-width: calc(33.333% - 20px);

    @media (max-width: 991px) {
        flex: unset;
        width: 100%;
        max-width: calc(50% - 20px);
        margin-right: 10px;
        margin-bottom: 10px;
    }
    @media (max-width: 600px) {
        flex: unset;
        width: 100%;
        max-width: 100%;
        max-width: 100%;
    }
`;

const AppCard = styled.article`
    display: flex;
    gap: 20px;
    padding: 24px;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--Surface-2, #221f1d);
    border: 1px solid rgba(65, 59, 55, 0.3);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 2px 14px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;

const AppLogo = styled.img`
    width: 44px;
    height: 44px;
    border-radius: 200px;
    background-color: rgba(182, 164, 154, 0.4);
    align-self: start;
`;

const AppDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

const AppName = styled.h3`
    color: #f1ede9;
    font-size: 14px;
    font-weight: 600;
    line-height: 143%;
    margin: 0;
    text-align: left;
`;

const AppDescription = styled.p`
    color: rgba(199, 185, 178, 0.8);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin: 0;
`;

const CallToActionSection = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    background-color: rgba(34, 31, 29, 0.6);
    padding: 160px 56px;

    @media (max-width: 991px) {
        padding: 40px 20px;
    }
`;

const CallToActionBackground = styled.img`
    position: absolute;
    inset: 0;
    top: 0;
    // height: 100%;
    width: 100%;
    object-fit: cover;
`;

const CallToActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 599px;
    width: 100%;
    position: relative;
    z-index: 1;
`;

const CallToActionTitle = styled.h2`
    color: #f1ede9;
    font-size: 44px;
    font-weight: 600;
    letter-spacing: -0.88px;

    @media (max-width: 991px) {
        font-size: 36px;
    }
`;

const CallToActionFeatures = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 16px;
    margin-top: 32px;
    color: #c2b7b1;
`;

const Feature = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

const FeatureIcon = styled.img`
    width: 28px;
    aspect-ratio: 1;
    object-fit: cover;
    fill: rgba(175, 71, 38, 0.2);
`;

const CallToActionButtons = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 40px;
`;

export default Landing;
