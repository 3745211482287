import React, { Suspense, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import PageLoader from "./components/PageLoader";

import Header from "./components/ConnecButton";
import Sidebar from "./components/Sidebar";
import Home from "./view/Home";
import "./App.scss";
import { NFTCharacter, Lobby } from "./view/NFTGame";

import GlobalStyle from "./assets/style/css/GlobalStyle";

import { WalletConnect } from "./components/Gadget";
//// import './assets/style/css/globals.css';
import { Flex } from "crox-new-uikit";
import useMediaQuery from "use-mediaquery";
import abi from "./utils/StakingToken.json";
//import Profile from "./Profile";
////import "./App.css";
import {
    WagmiConfig,
    createClient,
    defaultChains,
    configureChains,
    useAccount,
} from "wagmi";
import { client } from "./libraries/client";
import Landing from "./view/Landing";
import HeaderSite from "./components/HeaderSite";
import Footer from "./components/Footer";

const App = () => {
    const [collapse, setCollapse] = useState(true);
    const [toggle, setToggle] = useState(false);
    const [isWalletConnected, setIsWalletConnected] = useState(false);
    const [userName, setUserName] = useState("");
    const [displayedUserName, setDisplayedUserName] = useState("initial name");
    const [error, setError] = useState(null);
    const contractAddress = "0x610178dA211FEF7D417bC0e6FeD39F05609AD788";
    const contractABI = abi.abi;
    const location = useLocation();
    const { address } = useAccount();
    console.log('location', location);
    const handleChild = (status) => {
        console.log("Status", status);
        status ? setIsWalletConnected(true) : setIsWalletConnected(false);
    };

    const isMobile = useMediaQuery("(max-width: 600px)");
    const queryParameters = new URLSearchParams(window.location.search)
    const refID = queryParameters.get("referralID")

    return (
        <div className="App">
            <GlobalStyle />
            <WagmiConfig client={client}>
                {/* <Routes> */}
                {/* <Route
                        path="/landing"
                        element={<Landing collapse={collapse} />}
                    /> */}

                <Flex
                    flexDirection="column"
                    style={{
                        minHeight: "100vh",
                        justifyContent: "space-between",
                    }}
                >
                    {/* <Header
                        handleChild={handleChild}
                        toggle={toggle}
                        setToggle={setToggle}
                    /> */}
                    <HeaderSite />
                    <Flex>
                        {/* <Sidebar
                            collapse={collapse}
                            setCollapse={setCollapse}
                            toggle={toggle}
                            setToggle={setToggle}
                        /> */}

                        {!address ? (
                            <Flex
                                justifyContent="center"
                                flexDirection="column"
                                alignItems="center"
                                mt={isMobile ? 100 : -30}
                            >
                                <WalletConnect />
                            </Flex>
                        ) : (
                            <Suspense fallback={<PageLoader />}>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={<Home refID={refID} collapse={collapse} />}
                                    />
                                    <Route
                                        path="/landing"
                                        element={
                                            <Landing collapse={collapse} />
                                        }
                                    />

                                    <Route
                                        path="/nftch"
                                        element={
                                            <NFTCharacter refID={refID} collapse={collapse} />
                                        }
                                    />
                                    <Route
                                        
                                        path="/lobby"
                                        element={<Lobby refID={refID} collapse={collapse} />}
                                        
                                    />
                                </Routes>
                            </Suspense>
                        )}
                    </Flex>
                    <Footer />
                </Flex>
                {/* </Routes> */}
            </WagmiConfig>
        </div>
    );
};

export default App;
