import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

    

    body {
        margin: 0;
        font-family: system-ui;
        background-color: var(--Surface, #171615);
        display: flex;
        color: #fff;
        overflow: auto;
    }

`;

export default GlobalStyle;
